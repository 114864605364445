import { useForm, SubmitHandler } from "react-hook-form"
import { memo, useContext, useState } from "react"
import { InputFileGeneral } from '../../interfaces/upload.request.interface'
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { BootStrapSpinner } from "../ui/views/spinner";
import { cacheGet } from "../../services/cache";
import { UploadSingleMultiComponent } from "./single-milti.component";
import { UiNotificationsAlertsComponent } from "../ui/notifications/alerts.component";
import { UploadCsvCompareImportSelectorComponent } from "./csv-compare-import";
import { randomStr, scrollToElement } from "../../helpers/util.helper";
import { AlertDanger } from "../ui/views/alerts";
import { IResponse } from "../../interfaces/response.interface";
import { ModalContext } from "../../providers/modal.provider";
import { AlertContext } from "../../providers/alert.provider";
import { AccountContext } from "../../providers/account.provider";
import { loop } from "../../helpers/arrayworks.helper";
import { IAnyType } from "../../interfaces/form-builder.interface";
import { IContextChildren } from "../../interfaces/context-children.inteface";
import IComponentState, { ComponentStateLoadingDto, ComponentStateReadyDto } from "../../interfaces/component-state.interface";
import ButtonBack from "../ui/buttons/button-back";
import __ from '../../services/translator'
import './styles.scss'

export type IUploadCsvAnyResponse<T> = {
    success: boolean,
    data: T,
    error?: string
  }

// type UploadedW3 = {
//   cid: string,
//   carCid: string
// }

// type UploadResponse = {
//   error?: string,
//   code?: number,
//   data?: number,
//   count?: number,
//   success: boolean,
//   uploaded: Array<UploadedW3>
// }

interface IUploadCsv
{
    title: string,
    group: any,
    success: any,
    alerting?: any,
    alertingIgnoreInternal?: boolean
    onCloseEvent?: any
}

export const UploadCsv = memo(function UploadCsv({ group, onCloseEvent }: IUploadCsv)
{
    const [ tabs, setTabs ] = useState<string>('csv')
    const [ toggleDone, setToggleDone ] = useState<boolean>(false)
    const { setModalActive } = useContext(ModalContext)
    const { setMsg } = useContext(AlertContext)
    const show = (name: string): boolean => tabs === name
    const close = () => {
        if(onCloseEvent)
            onCloseEvent()
        setMsg('')
        setModalActive(false)
    }
    return (
    <div id="csv-uploader-block-container">
        <UiNotificationsAlertsComponent />
        <ul className="tabs mb-4">
            <li className={show('csv')? 'active' : ''}><Button size="medium" onClick={() => setTabs('csv') }>Enter Animals Via Template</Button></li>
            <li className={show('form')? 'active' : ''}><Button size="medium" onClick={() => setTabs('form') }>Enter Animals Manually</Button></li>
        </ul>
        { show('csv') && !toggleDone? <CSVUploaderViewComponent  {...{group, toggleDone, setToggleDone } } />: null }
        { show('form') && !toggleDone? <UploadSingleMultiComponent gid={ group } toggleDone={ toggleDone } setToggleDone={ setToggleDone } /> : null }
        { toggleDone? <div className="align-middle"><Button variant="contained" className="corp" onClick={ close }>Finish</Button></div> : null }
    </div>
    )    
})

interface ICSVUploaderViewComponent
{
    group: any
    toggleDone: boolean
    setToggleDone: any
}

export const CSVUploaderViewComponent = ({ group, toggleDone, setToggleDone }: ICSVUploaderViewComponent) =>
{
    const [ fillSample, setFillSample ] = useState<boolean>(false)
    const [ toggleUi, setToggleUi ] = useState<boolean>(false)
    const [ wizardType, setWizardType ] = useState<string>('')
    const { setModalActive } = useContext(ModalContext)

    return (
        <>
        {/* <Typography variant="h5" className="mb-4">Enter via Template</Typography> */}
        <div className="your-template">
            { !toggleUi? 
            <>
                { wizardType === ''?
                <div>
                    <p>Let's get started adding animals to your herd group.</p>
                    <div className="align-middle mt-4">
                        <div className="col-count-2 gapped-2 gapped1-md col-c1-sm">
                            <div className="wizard-option text-center">
                                <Typography variant="h6">Use Our CSV Template</Typography>
                                <div>
                                    <Button variant="outlined" className="mt-2 mb-4" onClick={() => { setWizardType('cpv') }}>Get Started&nbsp;<i className="fas fa-arrow-right"></i></Button>
                                </div>
                                <div>
                                    <p>Use our template to copy/paste your data into to guarantee a successful import.</p>
                                </div>
                            </div>
                            <div className="wizard-option text-center">
                                <Typography variant="h6">Use Your CSV File</Typography>
                                <div>
                                    <Button variant="outlined" className="mt-2 mb-4" onClick={() => { setWizardType('ready') }}>Get Started&nbsp;<i className="fas fa-arrow-right"></i></Button>
                                </div>
                                <div>
                                    <p>Use your own CSV file with each animal's data from your scanner application or herd management application.</p>
                                </div>
                            </div>
                        </div>
                        <div className="align-middle mt-4">
                            <Button
                                variant="outlined"
                                className="mt-4 corp"
                                onClick={() => {
                                    setModalActive(false)
                                }}>
                                    <ButtonBack text={toggleDone? 'Finish' : 'Cancel'} />
                            </Button>
                        </div>
                    </div>

                </div> : null }

                { wizardType === 'cpv'?
                <>
                    <Typography variant="h5" className="mb-3">Using Our CSV Template</Typography>
                    <CSVUploaderViewOurCSVTemplateComponent {...{group, fillSample, setFillSample}} />
                    <div className="align-middle mt-4">
                        <div className="col-count-2 gapped col-c1-md">
                            <Button variant="outlined" className="mt-2 mb-4" onClick={() => { setWizardType('') }}><i className="fas fa-arrow-left"></i>&nbsp;Back</Button>
                            <Button variant="outlined" className="mt-2 mb-4" onClick={() => { setWizardType('ready') }}>Upload Now&nbsp;<i className="fas fa-arrow-right"></i></Button>
                        </div>
                    </div>
                </> : null }
            </>
            : null }

            { wizardType === 'ready'?
            <>
                <Typography variant="h5" className="mb-3">Upload Your Animals</Typography>
                <p>You will need to match the data columns from your file upload with our requested data columns. You are required to provide the following for each animal: breed, PCT EID, animal location.</p>
                <UploadCsvCompareImportSelectorComponent
                    gid={ group }
                    setToggleUi={ setToggleUi }
                    onSuccessEvent={ () => {
                        setToggleDone(true)
                        setToggleUi(false)
                        setWizardType('')
                        scrollToElement('csv-uploader-block-container')
                    } }
                />
                { !toggleUi?
                <div className="align-middle">
                    <Button variant="outlined" className="mt-2 mb-4" onClick={() => setWizardType('')}><i className="fas fa-arrow-left"></i>&nbsp;Back</Button>
                </div> : null }
            </>
                : null
            }

        </div>
        </>
    )
}

export const CSVUploaderViewOurCSVTemplateComponent = ({group, fillSample, setFillSample}: any) => {
    return (
        <>
             <p>If you would like to see sample data in the downloaded template, select the "Check to fill with sample data" checkbox before clicking the "Download Template" button. Remember to remove all sample data from the template before uploading.</p>
            <div className="align-middle mt-4">
                <div className="col-count-2 gapped" style={{gridTemplate: '1fr / auto auto'}}>
                    <a href={ `${import.meta.env.VITE_APIENDPOINT}/generate/csv/${group}?jwtToken=${cacheGet('token')}&fill=${fillSample? '1' : ''}` }>
                        <Button className="py-3" variant="contained">Download CSV Template</Button>
                    </a>
                    <FormControlLabel value={ fillSample } control={<Checkbox onChange={(e) => {
                        setFillSample(e.target.checked)
                    }} />} label='Check to fill with sample data.' />
                </div>
            </div>
        </>
    )
}


export type IUploadCsvAny = {
    onSubmit: any
}

export const UploadCsvAny = memo(function UploadCsvAny({ onSubmit }: IUploadCsvAny)
{
    const [ fileSending, setFileSending ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState<string>('')
    const { register, handleSubmit, reset, watch } = useForm<InputFileGeneral>();
    const formData = watch()

    const randNum: string = randomStr(5)
    const idVal = `csv-uploader-${randNum}`

    const onSubmitEvent: SubmitHandler<InputFileGeneral> = async data => {
        // Start spinner
        setLoading(true)
        // Run the submission funciton being passed from parent
        onSubmit(data, (success: boolean, response: IResponse<any>) => {
            console.log(success, response)
            // On parent call finished, stop spinner
            setLoading(false)
            // Stop sending notification
            setFileSending(false)
            // If the success is called from parent, reset form
            if(!success){
                setError(response.error || 'An error occurred uploading your file.')
            }
            reset()
            setFileSending(false)
        },
        // Send the name of the id
        idVal)
    };

    return (
        loading?
        <BootStrapSpinner /> : 
        <div className="align-middle">
            <form className={ `csv-file-uploader ${fileSending? 'disabled' : ''}` }>
                { (error !== '')? <div className="mt-3"><AlertDanger text={ error } onCloseEvent={ () => setError('') } /></div> : null }
                <input id={ idVal } type="file" { ...register("file") } disabled={ fileSending } />
                <Button className="m-4" variant="outlined" onClick={handleSubmit(onSubmitEvent)} disabled={ fileSending || (!formData.file || formData?.file?.length === 0) }>{ __('Submit', 'submit') }</Button>
            </form>
        </div>
    )
})

interface IUploadCsvAnyComponent extends IContextChildren
{
    id?: string
    fileInputId?: string
    fieldName?: string
    service: string
    frontend?: boolean
    options: IAnyType<{ label: string, name: any, accepts: string[] }[]>
    fileTypes?: string[]
}

export const GenericUploadFileFormComponent = ({
    children,
    id = 'upload-form',
    fileInputId = 'file-input',
    fieldName = 'upload',
    service,
    frontend = false,
    options,
 }: IUploadCsvAnyComponent) => {
    const [ fileTypes, setFileTypes ] = useState<string[]>(['.xlsx'])
    const [ state, setState ] = useState<IComponentState<any>>(ComponentStateReadyDto)
    const { setMsg, msg, setType, type } = useContext(AlertContext)
    const { account } = useContext(AccountContext)
    //const [ value, setValue ] = useState<number>(0)

    // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //     if(!event)
    //         console.log('no event')
    //   setValue(newValue);
    // };
  
    const reset = () => {
      const f: any = document.getElementById(fileInputId)
      if(f)
        f.value = ''
    }

    const onSubmitEvent = (e: any) => {
        e.preventDefault();
        setState(ComponentStateLoadingDto)
        setMsg('')
        const formData = new FormData();
        const fileInput: any = document.getElementById(fileInputId);
        if(fileInput && fileInput.files) {
          formData.append(fieldName, fileInput?.files[0] || '')
          formData.append('ftype', state?.data?.description || '')
        }

        const httpClient = async (formData: any) => await fetch(service, {
            method: 'POST',
            body: formData,
            headers: !frontend? {
                Authorization: `Bearer ${cacheGet('token')}`,
            } : {
                Authorization: `Bearer ${cacheGet('token')}`,
                'X-APP-UID': account?.ID,
                'X-APP-DESCRIPTION': state?.data?.description || ''
            } 
        }).then(r => r.json())
        
        httpClient(formData).then(r => {
          if(r.success) {
            fileInput.value = ''
            setType(true)
            setMsg('Upload successful')
            setState({...state, data: {file: ''}})
            reset()
          } else {
            setType(false)
            setMsg(r.error || 'An error occurred uploading your file.')
          }
          setState(ComponentStateReadyDto)
        })
      }

    return (
    <form id={ id } encType="multipart/form-data" onSubmit={onSubmitEvent}>
        <FormControl className="col-count- gapped">
            { (msg !== '')? <div className={`alert alert-${type? 'success' : 'danger'}`}>{ msg }</div> : null }
            
            { children || null }

            { options? <FormLabel component="legend" className='required'>What kind of upload is this?</FormLabel> : null }
            <div className="col-count-2 gapped col-c1-md gapped">
                { options?
                <Select
                    value={state?.data?.description || ''}
                    required
                    onChange={(e: any) => {
                        setState((arr: any) => ({...arr, data: {...arr?.data, description: e.target.value } }))
                        setFileTypes(Array.isArray(options) ? options.find((v: any) => v.name === e.target.value)?.accepts || [] : [])
                    }}
                >
                    { loop(options, (v: any, k: any) => (
                        <MenuItem key={ k } value={ v.name }>{ v.label }</MenuItem>
                    )) }
                </Select> : null }

                <TextField
                    type="file"
                    name={ fieldName }
                    id={ fileInputId }
                    inputProps={{
                        accept: fileTypes.join(',')
                    }}
                    required
                    onChange={(e: any) =>{
                        setType(true)
                        setMsg('')
                        setState((arr: any) => ({...arr, data: {...arr?.data, file: e.target.value } }))
                    }}
                />
            </div>
        </FormControl>
        <div className="align-middle mt-3">
            { !state.loading? 
            <div className="d-flex justify-content-center gapped">
                <Button type='submit' className='corp' variant='contained' disabled={(!state?.data?.file || state?.data?.file === '') || state?.data?.description === ''}><i className="fas fa-arrow-circle-up"></i>&nbsp;Upload</Button>
            </div> : 
            <BootStrapSpinner />
            }
        </div>
    </form>)
}